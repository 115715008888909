import React, { useState, useEffect, useCallback, useRef } from "react";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import { Link } from "gatsby";
import { getSearchParams } from "gatsby-query-params"
//import axios from "axios";
import _fetch from "../../utils/_fetch";
import Checkbox from "../../Components/Checkbox";
import radio from "../../Components/Radio";
import Layout from "../../Components/Layout";
import TimeElapsed from "../../Components/TimeElapsed";
import Results from "../../Components/Results";
import Box from "../../Components/Box2";
import { ArrowAtgal, ArrowToliau } from "../../Components/Buttons";

const validationSchema = Yup.object().shape({
  answer: Yup.string().required("Laukas yra privalomas"),
})






export default () => {

  const params = getSearchParams()
  useEffect(() => {
    if(params.t)
      {
        localStorage.setItem("token", params.t)
      }
  }, [params])


  const [question, setQuestion] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [currentQuestion, setCurrentQuestion] = useState(0)
  //const [isMultiple, setIsMultiple] = useState(false)

  const startTime = new Date (Date.now()); 

  const timerBarIntRef = useRef();
  const timerBarIfRef = useRef();
  const timerStyleRef = useRef();

  const formikRef = useRef(null);

  const clearIntervals = () => {
    clearInterval(timerBarIntRef.current);
    clearInterval(timerBarIfRef.current);
    clearInterval(timerStyleRef.current);
  };

  useEffect(() => {
    let timerBar = 100;
    timerBarIntRef.current = setInterval(() => timerBar-=(100/60), 1000);
    //setInterval(() => console.log(timerBar.toFixed(3)), 1000);
  
    timerBarIfRef.current = setInterval(() => {
      if(timerBar <= 0)
      {

        console.log(formikRef.current);
        if (formikRef.current) {
          // Trigger form submission using submitForm function
          formikRef.current.submitForm();
        }
        //onSubmit();
        //console.log(timerBar.toFixed(3));
        clearIntervals();
      }
    }, 1000);
  
    const elementP = document.getElementById("progressBar");

    if (elementP) {
      timerStyleRef.current = setInterval(() => elementP.style.width = timerBar.toFixed(3) + '%', 1000);
  
  } else {
      
  }
   
   return clearIntervals;
  })
  
  const getQuestion = useCallback(async questionIndex => {
    setIsLoading(true)





    _fetch(`question/${questionIndex}`, "GET", null, true).then(({ data }) => {
      if (!data.completed) {
        setCurrentQuestion(questionIndex)
        setQuestion({
          ...data,
          // choices: data.choices.map(choice => {
          //   return {
          //     ...choice,
          //     selected: false,
          //   }
          // }),
        })
      } else {
        _fetch("questionresults", "GET", null, true).then(
          ({ data: answers }) => {
            setCompleted({
              ...data,
              ...answers,
            })
          }
        )
      }
      setIsLoading(false)
    })
  }, [])

  //useEffect(() => {
  //  getQuestion(currentQuestion + 1).then(() => {
  //    setIsMultiple(question?.type);
  //  })
  //}, [currentQuestion, getQuestion, question?.type])

  useEffect(() => {
    getQuestion(currentQuestion + 1)
  }, [])

  const isError = () => {
    return question && question.status === "error"
  }

  

  const onSubmit = (values, { resetForm }) => {
    _fetch(
      `question/${currentQuestion}`,
      "POST",
      {
        questionId: question.id,
        answerId: values.answer,
      },
      true
    ).then(() => {
      resetForm({})
      getQuestion(currentQuestion + 1)
    })
  }

  //const getInitialValues = () => {
  //  if(isMultiple)
  //  {
  //    return {
  //      choices: question.choices.filter(choice => choice.selected).map(choice => choice.value)
  //    }
  //  }
  //  else {
  //    const answer = question.choices.find(choice => choice.selected)
  //    return {
  //      answer: answer ? answer.value : '',
  //   }
  //  }
  //}
  const getInitialValues = () => {
    const answer = question.choices.find(choice => choice.selected)

    return {
      choices: question.choices,
      answer: answer ? answer.value : null,
    }
  }
  

  if (!question && !completed) {
    return null
  }

  const hasMedia = () => {
    return question && question.question && question.question.html
  }
  const hasMetrics = () => {
    return question && question.question && question.question.metrics
  }

 

  

  const renderTestQuestion = () => {
    return (
      <>
      <div className="test-page">

        <Box>
        <div className="test-page--progress">
          <div className="test-page--progress-track">
            <div
              className="test-page--progress-track-filler"
              id="progressBar"
            ></div>
          </div>

          <div className="tar">
            <div>Laikas</div>
            <div className="test-page--progress-value">
              <TimeElapsed startDate={question.started} />
            </div>
          </div>
        </div>
        <Formik
          enableReinitialize={true}
          validationSchema={validationSchema}
          initialValues={getInitialValues()}
          onSubmit={onSubmit}
          innerRef={formikRef}
          component={({ values }) => {
            return (
              <Form>
                <div className="text-container" style={{textAlign:"left"}}>
                <h5>{currentQuestion}.&nbsp;{question.question.text}</h5>

                <p id="body"
                    dangerouslySetInnerHTML={{
                      __html: question.question.metrics,
                    }}
                ></p>
                {hasMedia() && (
                    <div className="test-page--assets-wrapper">
                      <div className="test-page--assets">
                        {question.question.html.map((item, index) => (
                          <img
                            key={`question_asset_${values.id}_${index}`}
                            src={item}
                            alt=""
                          />
                        ))}
                      </div>
                    </div>
                 )}
                </div>
                <div className="test-page--questions">
                <Field
                  type="hidden"
                  name="answer" // Change the name if needed
                  value="0" // Set the value accordingly
                />
                {values.choices
                    .map((item, index) => (
                      <Field
                        component={radio}
                        required
                        defaultChecked={item.selected}
                        key={`answer_${values.id}_${index}`}
                        value={item.value}
                        name="answer"
                      >
                        <p id="body">{item.answer}</p>
                      </Field>
                    ))
                  }
                </div>

                <div className="test-page--actions">
                  <ArrowToliau type="submit">
                    Kitas
                  </ArrowToliau>
                </div>
              </Form>
            )
          }}
        />
        </Box>
        </div>
      </>
    )
  }
  
  return (
    <Layout compact={!completed}>
      <div className="test-page">
        <Box>
          {isError() && <div className="tac">{question.message}</div>}
          {!completed &&
          !isLoading &&
          question.question &&
          renderTestQuestion()}
          {completed && (
            <Results
              answers={completed.answers_mock}
              message={completed.message}
              time={completed.results.time}
              answered={completed.results.correct}
              totalQuestions={completed.results.total}
              shoutout={completed.shoutout}
            />
          )}
          
        </Box>
      </div>
    </Layout>
  )
}